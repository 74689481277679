import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '@edx/frontend-platform/i18n';
import { SearchField } from '@edx/paragon';
import { FACETS } from '../constants';
import FacetDropdown from './FacetDropdown';

/**
 * Search Bar component that renders search input, search button and Facets selects.
 */
const SearchBar = ({
  facets,
  queryString,
  handleSearch,
  handleFacetChange,
  intl,
}) => (
  <div className="search-wrapper">
    <SearchField
      autoComplete="off"
      onSubmit={handleSearch}
      value={queryString}
      placeholder={`${intl.formatMessage({ id: 'search.forCourses', defaultMessage: 'Search for courses' })}`}
      onChange={value => {
        if (!value && value !== queryString) {
          handleSearch('');
        }
      }}
    />
    <div className="facet-dropdown-holder">
      <div className="facet-dropdown-title">
        {`${intl.formatMessage({ id: 'filters', defaultMessage: 'Filters' })}`}
      </div>
      <div className="facet-dropdown">
        {Object.keys(facets).length > 0 && Object.values(FACETS).map((facetName) => (
          <FacetDropdown
            key={facetName}
            name={facetName}
            options={Object.keys(facets[facetName].terms)}
            handleFacetChange={handleFacetChange}
          />
        ))}
      </div>
    </div>
  </div>
);

SearchBar.propTypes = {
  intl: intlShape.isRequired,
  facets: PropTypes.shape({}),
  selectedFacets: PropTypes.shape({}),
  handleSearch: PropTypes.func.isRequired,
  handleFacetChange: PropTypes.func.isRequired,
  queryString: PropTypes.string.isRequired,
};

SearchBar.defaultProps = {
  facets: {},
  selectedFacets: {},
};

export default injectIntl(SearchBar);
